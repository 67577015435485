// @import '~react-perfect-scrollbar/dist/css/styles.css';
// @import './prismjs';

@font-face {
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Montserrat//Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat SemiBold";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Montserrat//Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat SemiBoldItalic";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Montserrat//Montserrat-SemiBoldItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat Bold";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Montserrat//Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat BlackItalic";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Montserrat//Montserrat-BlackItalic.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@media screen and (min-width : 800px) {
  body {
    background-color: #ffffff;
    height: 100%;  
  }
}

@media screen and (max-width : 800px) {
  body {
    display: flex;
  }
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

:not(pre) > code {
  font-family: Montserrat, Inconsolata, Monaco, Consolas, "Courier New", Courier,
    monospace;
  background-color: #eeeeee;
  padding: 0px 0px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 12px;
}
